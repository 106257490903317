import React from 'react'
import Login from '../components/Auth/Login'


const AuthPage = () => {
  return (
    <Login/>
  )
}

export default AuthPage